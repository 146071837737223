import { useGetInfo } from '@ping/api';
import { ReactNode, useEffect, useState } from 'react';
import { selectedMarketCurrenciesSelector, selectedMarketSelector, useMarketStore } from '@ping/stores/market.store';
import {
  filterPairs,
  coinTabOptions as coinMarketTabOptions,
  handleSearch as handleMarketSearch,
  marketsKeys,
} from '../helpers';

interface IUseUpdateMarketPairs {
  pairs: [string, IMarketModified[]][];
  showFav?: boolean;
  icon: ReactNode;
  isShowMarketAll?: boolean;
}

export const useUpdateMarketPairs = ({
  pairs,
  icon,
  showFav = true,
  isShowMarketAll = false,
}: IUseUpdateMarketPairs) => {
  const selectedMarketCurrencies = useMarketStore(selectedMarketCurrenciesSelector) || {
    base: marketsKeys.ALL_MARKETS,
    counter: '',
  };
  const [showMarket, setShowMarket] = useState<string>(
    isShowMarketAll ? marketsKeys.ALL_MARKETS : selectedMarketCurrencies.base
  );
  const [filterQuery, setFilterQuery] = useState('');
  const selectedMarket = useMarketStore(selectedMarketSelector);
  let visiblePairs = [];
  const { data: marketInfo, isLoading: marketInfoLoading } = useGetInfo({ query: { select: ({ pairs }) => pairs } });

  if (!marketInfoLoading) {
    pairs.forEach(([coin, instruments]) => {
      instruments.forEach(instrument => {
        const instrumentInfo = marketInfo?.[instrument.instrument];
        if (!instrumentInfo?.hidden) {
          if (visiblePairs[coin]) {
            visiblePairs[coin].push(instrument);
          } else {
            visiblePairs[coin] = [instrument];
          }
        }
      });
    });
  }

  /* convert to the format that we can show */
  visiblePairs = Object.entries(visiblePairs);

  useEffect(() => {
    /* useful when user select fav and then logout */
    if (!showFav && showMarket === marketsKeys.FAVORITE_MARKETS) {
      setShowMarket(selectedMarketCurrencies.base);
    }
  }, [showFav]);

  /* ToDo : don't know if this is good UX but seems reasonable to show All when user try to search */
  useEffect(() => {
    if (filterQuery) {
      setShowMarket(marketsKeys.ALL_MARKETS);
    }
  }, [Boolean(filterQuery)]);

  const coinTabOptions = coinMarketTabOptions(visiblePairs, icon, showFav);

  filterPairs(pairs, showMarket);

  const handleSearch = event => handleMarketSearch(event, setFilterQuery);

  return {
    showMarket,
    setShowMarket,
    filterQuery,
    setFilterQuery,
    visiblePairs,
    selectedMarket,
    coinTabOptions,
    filterPairs,
    handleSearch,
  };
};
